import React, {Suspense} from 'react';
import {Canvas} from "@react-three/fiber";
import styled from "@emotion/styled";
import {Bounds, OrbitControls, Stars} from "@react-three/drei";
import Earth from "./components/Earth";
import Venus from "./components/Venus";
import Mars from "./components/Mars";
import Sun from "./components/Sun";
import Jupiter from "./components/Jupiter";
import Saturn from "./components/Saturn";
import Uranus from "./components/Uranus";
import Neptune from "./components/Neptune";
import Mercury from "./components/Mercury";
import ClickToFit from "./utils/ClickToFit";

function App() {
  return (
    <div className="App">
      <ThreeCanvasWrapper>
        <Canvas
          camera={{
            far: 500000,
            fov: 35,
            position: [-5000, 5000, 50],
          }}
        >
          {/*<ambientLight intensity={2}/>*/}
          <Stars radius={100000} depth={0} factor={100} count={500000} fade/>
          <Suspense fallback={null}>
            <Bounds observe fit margin={1.2} damping={6}>
              <ClickToFit>
                <Sun position={[0, 0, 0]}/>
                <Mercury position={[2000, 0, 0]}/>
                <Venus position={[3500, 0, 0]}/>
                <Earth position={[5000, 0, 0]}/>
                <Mars position={[7500, 0, 0]}/>
                <Jupiter position={[25000, 0, 0]}/>
                <Saturn position={[50000, 0, 0]}/>
                <Uranus position={[100000, 0, 0]}/>
                <Neptune position={[200000, 0, 0]}/>
              </ClickToFit>
            </Bounds>
          </Suspense>
          {/*<CameraControls makeDefault dollySpeed={0.35}/>*/}
          <OrbitControls makeDefault minPolarAngle={1} maxPolarAngle={Math.PI / 1.5}/>
        </Canvas>
      </ThreeCanvasWrapper>
    </div>
  );
}

export default App;

const ThreeCanvasWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`