import {useGLTF} from "@react-three/drei";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import React, {useRef} from "react";
import THREE from "three";

type GLTFResult = GLTF & {
  nodes: {
    Cube001: THREE.Mesh;
  };
  materials: {
    None: THREE.MeshStandardMaterial;
  };
};

const Sun = (props: JSX.IntrinsicElements["group"]) => {
  const {nodes, materials} = useGLTF('/resource/sun/sun.glb') as GLTFResult;

  const sizeRef = useRef<THREE.Mesh>(null!);

  return <group {...props} dispose={null}>
    <pointLight intensity={70000}
                decay={1}
                color={"#ffffff"} distance={1000000} castShadow
                position={[0, 0, 0]}/>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.Cube001.geometry}
      material={materials.None}
      rotation={[Math.PI / 2, 0, 0]}
      scale={1090}
      ref={sizeRef}
    />
  </group>
}

useGLTF.preload('/resource/sun/sun.glb')

export default Sun;