import {Html, useGLTF} from "@react-three/drei";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {useFrame} from "@react-three/fiber";
import {useRef} from "react";
import config from "../utils/config";

type GLTFResult = GLTF & {
  nodes: {
    Cube001: THREE.Mesh;
  };
  materials: {
    ["Default OBJ"]: THREE.MeshStandardMaterial;
  };
};

const Planet = (props: JSX.IntrinsicElements["group"]) => {
  const meshRef = useRef<THREE.Group>(null!);
  const {nodes, materials} = useGLTF('/resource/earth/earth_cloud.glb') as GLTFResult;

  const revolutionDay = 365;
  const earthRevolutionDay = 365;
  const earthRevolutionTime = config.EARTH_REVOLUTION_TIME; // 한 바퀴 도는데 걸리는 시간 (s)
  const radius = 5000;
  const earthR = earthRevolutionTime / earthRevolutionDay;

  const randValue = 512;


  useFrame((state, delta) => {
    const theta = state.clock.getElapsedTime() + randValue; // 실행 시간
    const p = Math.PI / (revolutionDay * earthR);
    meshRef.current.position.setX(Math.cos(theta * p) * radius);
    meshRef.current.position.setZ(Math.sin(theta * p) * radius);
  });

  return <group
    ref={meshRef}  {...props} dispose={null}>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.Cube001.geometry}
      material={materials["Default OBJ"]}
      scale={0.1}
    >
      <Html distanceFactor={2000} position={[0, 0, 0]}>
        <div style={{color: "rgba(255,255,255,0.65)", pointerEvents: "none", userSelect: "none"}}>Earth</div>
      </Html>
    </mesh>
  </group>
}

useGLTF.preload('/resource/earth/earth_cloud.glb')

export default Planet;