import {Html, useGLTF} from "@react-three/drei";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {useFrame} from "@react-three/fiber";
import {useRef} from "react";
import config from "../utils/config";

type GLTFResult = GLTF & {
  nodes: {
    Neptune: THREE.Mesh;
  };
  materials: {
    ["Default OBJ.001"]: THREE.MeshStandardMaterial;
  };
};


const Planet = (props: JSX.IntrinsicElements["group"]) => {
  const meshRef = useRef<THREE.Group>(null!);
  const {nodes, materials} = useGLTF('/resource/neptune/neptune.glb') as GLTFResult;

  const revolutionDay = 165 * 365;
  const earthRevolutionDay = 365;
  const earthRevolutionTime = config.EARTH_REVOLUTION_TIME;
  const radius = 200000;
  const earthR = earthRevolutionTime / earthRevolutionDay;

  const randValue = 7234;


  useFrame((state, delta) => {
    const theta = state.clock.getElapsedTime() + randValue; // 실행 시간
    const p = Math.PI / (revolutionDay * earthR);
    meshRef.current.position.setX(Math.cos(theta * p) * radius);
    meshRef.current.position.setZ(Math.sin(theta * p) * radius);
  });

  return <group
    ref={meshRef}  {...props} dispose={null}>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.Neptune.geometry}
      material={materials["Default OBJ.001"]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={1}
    >
      <Html distanceFactor={2000} position={[0, 0, 0]}>
        <div style={{color: "rgba(255,255,255,0.65)", pointerEvents: "none", userSelect: "none"}}>Neptune</div>
      </Html>
    </mesh>
  </group>
}

useGLTF.preload('/resource/neptune/neptune.glb')

export default Planet;